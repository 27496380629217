import React from "react";
import classNames from "classnames";

import logosNCoDe from "root/assets/NCoDe2030 1.svg";
import logos2020 from "root/assets/norte-2020-logos 1.svg";

import styles from "./index.module.css";

function Apoios() {
  return (
    <div className={styles.root}>
      <div className={styles.apoios}>
        <div className={classNames(styles.container, styles.logos)}>
          <a
            target="_blank"
            rel="noreferrer"
            href="/ficha_projeto_hidepixel.pdf"
            className={styles.link2020}
          >
            <img src={logos2020} alt="Portugal2020 logo" />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.incode2030.gov.pt/sioslife-sistema-interativo-de-inclusao-social"
            className={styles.incode}
          >
            <img src={logosNCoDe} alt="NCoDe logo" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Apoios;
