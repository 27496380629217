/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import { navigate } from "gatsby";
import PropTypes from "prop-types";
import classNames from "classnames";
import closeModalButton from "root/assets/closeModal.svg";
import emailIcon from "root/assets/thank-you_email-icon.svg";
import phoneIcon from "root/assets/thank-you_phone-icon.svg";
import Typography from "root/components/Typography";
import Button from "root/components/Button";
import { useTranslation } from "react-i18next";
import styles from "./thank-you-window.module.css";

const ThankYouWindow = ({ color }) => {
  const { t } = useTranslation();
  const iconClassNames = classNames(styles.contactsIcon, styles[color]);

  return (
    <div className={styles.ThankYouWindow}>
      <div className={styles.window}>
        {/* CLOSE BTN */}
        <div role="button" className={styles.closeModalButton}>
          <img
            alt="close button"
            src={closeModalButton}
            id="closeModalButton"
            onClick={() => navigate(-1)}
          />
        </div>

        {/* TITLE */}
        <Typography color={color} variant="h1" weight="medium">
          {t("thankYou.title")}
        </Typography>

        {/* MESSAGE */}
        <div className={styles.message}>
          <Typography color="black" variant="body">
            {t("thankYou.message")}
          </Typography>
        </div>

        {/* CONTACTS */}
        <div className={styles.contacts}>
          <Typography color="black" variant="body" weight="medium">
            <span className={iconClassNames}>
              <img alt="close button" src={emailIcon} />
            </span>
            comunicacao@sioslife.com
          </Typography>
          <Typography color="black" variant="body" weight="medium">
            <span className={iconClassNames}>
              <img alt="close button" src={phoneIcon} />
            </span>
            +351 253 676 018
          </Typography>
        </div>

        {/* RETURN BTN */}
        <div className={styles.returnBtn}>
          <Button color={color} handleClick={() => navigate(-1)}>
            <Typography>{t("thankYou.goBack")}</Typography>
          </Button>
        </div>
      </div>
    </div>
  );
};

ThankYouWindow.propTypes = {
  color: PropTypes.string.isRequired,
};

export default ThankYouWindow;
